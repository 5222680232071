/* tslint:disable */
/* eslint-disable */
/**
 * Toi.Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NewsItem,
    NewsItemFromJSON,
    NewsItemToJSON,
} from '../models';

export interface ToiNewsGetRequest {
    take?: number;
}

/**
 * 
 */
export class ToiNewsApi extends runtime.BaseAPI {

    /**
     */
    async toiNewsGetRaw(requestParameters: ToiNewsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NewsItem>>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ToiNews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsItemFromJSON));
    }

    /**
     */
    async toiNewsGet(requestParameters: ToiNewsGetRequest, initOverrides?: RequestInit): Promise<Array<NewsItem>> {
        const response = await this.toiNewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
