/* tslint:disable */
/* eslint-disable */
/**
 * Toi.Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockPrice
 */
export interface StockPrice {
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly priceConverted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    priceChange?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly priceChangeConverted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    percentageChange?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    dayHigh?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly dayHighConverted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    dayLow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly dayLowConverted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    previousClosePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    closePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly closePriceConvered?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly previousClosePriceConverted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    openPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockPrice
     */
    readonly openPriceConverted?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockPrice
     */
    exchangeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockPrice
     */
    market?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockPrice
     */
    marketState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockPrice
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof StockPrice
     */
    readonly convertedCurrency?: string;
}

export function StockPriceFromJSON(json: any): StockPrice {
    return StockPriceFromJSONTyped(json, false);
}

export function StockPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': !exists(json, 'price') ? undefined : json['price'],
        'priceConverted': !exists(json, 'priceConverted') ? undefined : json['priceConverted'],
        'priceChange': !exists(json, 'priceChange') ? undefined : json['priceChange'],
        'priceChangeConverted': !exists(json, 'priceChangeConverted') ? undefined : json['priceChangeConverted'],
        'percentageChange': !exists(json, 'percentageChange') ? undefined : json['percentageChange'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'dayHigh': !exists(json, 'dayHigh') ? undefined : json['dayHigh'],
        'dayHighConverted': !exists(json, 'dayHighConverted') ? undefined : json['dayHighConverted'],
        'dayLow': !exists(json, 'dayLow') ? undefined : json['dayLow'],
        'dayLowConverted': !exists(json, 'dayLowConverted') ? undefined : json['dayLowConverted'],
        'previousClosePrice': !exists(json, 'previousClosePrice') ? undefined : json['previousClosePrice'],
        'closePrice': !exists(json, 'closePrice') ? undefined : json['closePrice'],
        'closePriceConvered': !exists(json, 'closePriceConvered') ? undefined : json['closePriceConvered'],
        'previousClosePriceConverted': !exists(json, 'previousClosePriceConverted') ? undefined : json['previousClosePriceConverted'],
        'openPrice': !exists(json, 'openPrice') ? undefined : json['openPrice'],
        'openPriceConverted': !exists(json, 'openPriceConverted') ? undefined : json['openPriceConverted'],
        'exchangeName': !exists(json, 'exchangeName') ? undefined : json['exchangeName'],
        'market': !exists(json, 'market') ? undefined : json['market'],
        'marketState': !exists(json, 'marketState') ? undefined : json['marketState'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'convertedCurrency': !exists(json, 'convertedCurrency') ? undefined : json['convertedCurrency'],
    };
}

export function StockPriceToJSON(value?: StockPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'priceChange': value.priceChange,
        'percentageChange': value.percentageChange,
        'volume': value.volume,
        'dayHigh': value.dayHigh,
        'dayLow': value.dayLow,
        'previousClosePrice': value.previousClosePrice,
        'closePrice': value.closePrice,
        'openPrice': value.openPrice,
        'exchangeName': value.exchangeName,
        'market': value.market,
        'marketState': value.marketState,
        'currency': value.currency,
    };
}

