/* tslint:disable */
/* eslint-disable */
/**
 * Toi.Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsItem
 */
export interface NewsItem {
    /**
     * 
     * @type {string}
     * @memberof NewsItem
     */
    readonly title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsItem
     */
    readonly link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NewsItem
     */
    readonly publishDate?: Date;
}

export function NewsItemFromJSON(json: any): NewsItem {
    return NewsItemFromJSONTyped(json, false);
}

export function NewsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'publishDate': !exists(json, 'publishDate') ? undefined : (new Date(json['publishDate'])),
    };
}

export function NewsItemToJSON(value?: NewsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

