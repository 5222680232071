/* tslint:disable */
/* eslint-disable */
/**
 * Toi.Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeRate
 */
export interface ExchangeRate {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRate
     */
    value?: number;
}

export function ExchangeRateFromJSON(json: any): ExchangeRate {
    return ExchangeRateFromJSONTyped(json, false);
}

export function ExchangeRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function ExchangeRateToJSON(value?: ExchangeRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'value': value.value,
    };
}

